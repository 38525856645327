import React from 'react'
import {graphql} from 'gatsby'
import {injectIntl, Link, useIntl} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"

import {Timeline, TimelineItem} from 'vertical-timeline-component-for-react'

import NpmLayout from "../layout";

const NewsTemplate = (props) => {
    const intl = useIntl()
    const lang = intl.locale

    let news = "";
    if (lang === "ru") {
        news = props.data.ru_news.edges
    } else if (lang === "en") {
        news = props.data.en_news.edges;
    }

    const next = props.pageContext.next
    const prev = props.pageContext.prev

    return (
        <NpmLayout>
            <Timeline lineColor={'#ddd'} animate={false}>
                {news.map(({node}) => (
                    <TimelineItem
                        key={node.frontmatter.date}
                        dateText={node.frontmatter.date}
                        style={{color: '#e86971'}}>
                        <h2>{node.frontmatter.title}</h2>
                        <div>{Parser(node.html)}</div>
                    </TimelineItem>
                ))}
            </Timeline>
            <nav style={{display: 'flex', justifyContent: 'space-between', marginLeft: `15%`}}>
                <div>
                    {prev && <Link to={`/${prev}`}><h1 style={{padding: 10, color: `red`}}>{`<`}---</h1></Link>}
                </div>

                <div style={{justifySelf: 'flex-end', marginRight: `10%`}}>
                    {next && <Link to={`/${next}`}><h1 style={{padding: 10, color: `red`}}>---></h1></Link>}
                </div>
            </nav>

        </NpmLayout>
    )
}

export default injectIntl(NewsTemplate)

export const query = graphql`
    query NewsQuery($limit: Int!, $skip: Int!) {
        ru_news: allMarkdownRemark(
            filter: {frontmatter: {content_type: {eq: "post"}, published: {ne: false}, language: {eq: "ru"}}},
            sort: {fields: [frontmatter___date], order: DESC},
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        slug
                        date(formatString: "DD.MM.YYYY")
                    }
                    html
                }
            }
        }

        en_news: allMarkdownRemark(
            filter: {frontmatter: {content_type: {eq: "post"}, published: {ne: false}, language: {eq: "en"}}},
            sort: {fields: [frontmatter___date], order: DESC},
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        slug
                        date(formatString: "DD.MM.YYYY")
                    }
                    html
                }
            }
        }
    }
`
